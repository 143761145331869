import { defineStore } from "pinia";


export const useCookiesConsentStore = defineStore('cookiesConsent', () => {

  const cookiesConsent = ref(useCookie('consent', { maxAge: 31536000 }));

  function setConsent(confirmed: string) {
    if (process.client) {
      cookiesConsent.value = confirmed;
    }
  }
  function getConsent() {
    if (process.client) {
      return cookiesConsent.value;
    }
  }

  function clear() {
    if (process.client) {
      cookiesConsent.value = null;
    }
  }
  return { setConsent, getConsent, clear }
})