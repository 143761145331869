<template>
  <div>
    <section v-if="!confirmedConsent" class="fixed p-4 mb-20 mx-4 md:max-w-md md:mb-4 bg-white border border-gray-200 bottom-0 left-0 right-0 
        rounded-2xl">
      <h2 class="font-semibold text-gray-800">🍪 Aviso de uso de cookies</h2>

      <p class="mt-4 text-sm text-gray-600">Nosso site utiliza cookies para melhorar a navegação. </p>
      <p>
        <NuxtLink to="/privacy-policy" class="text-blue-500 hover:underline text-sm">Políticas de privacidade
        </NuxtLink> -
        <NuxtLink to="/terms-and-conditions-of-use" class="text-blue-500 hover:underline text-sm">Termos e
          condições e uso</NuxtLink>.
      </p>
      <div class="flex items-center justify-end mt-4 gap-x-4 shrink-0">
        <!-- <button
          class="text-xs text-gray-800 underline transition-colors duration-300 dark:hover:text-gray-400 hover:text-gray-600 focus:outline-none">
          Gerenciar suas preferências
        </button> -->
        <!-- <button class="text-xs bg-transparent text-gray-700 font-medium py-2.5 px-4 border border-gray-500 rounded"
          @click.prevent="DisagreeConsent()">
          Rejeitar
        </button> -->
        <button class="text-xs bg-green-700 font-medium rounded text-white py-2.5 px-4 focus:outline-none"
          @click.prevent="AgreeConsent()">
          Ok
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import { useCookiesConsentStore } from "~~/stores/cookies-consent";
const store = useCookiesConsentStore();
const confirmedConsent = ref(false);

function AgreeConsent() {
  useGtagConsent(true)
  store.setConsent('true')
  confirmedConsent.value = store.getConsent() == 'true';
}

function DisagreeConsent() {
  useGtagConsent(false)
  store.clear()
  store.setConsent('false')
  confirmedConsent.value = store.getConsent() == 'false';
}

</script>

<style lang="scss" scoped></style>